import React, { Component } from "react";
import { analyticsLogEvent } from "../App";
import "./features-fix.css";

class ProductFeatures extends Component {
  componentDidMount() {
    analyticsLogEvent('webapp_product_feature_page')
    var myCarousel = document.getElementById("carousel-pages");

    myCarousel.addEventListener("slide.bs.carousel", function (ev) {
      var currentIndex = ev.relatedTarget.id;
      var elements = document.querySelectorAll(".nav-item-sidebar a");

      elements.forEach(function (element) {
        element.classList.remove("active-btn");
        elements[currentIndex - 1].classList.add("active-btn");
      });
    });
    console.log('GG', this.props)
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="container mb-5 section-1">
          <div className="p-2 p-md-5 pb-md-1">
            <h1 className="text-center mt-4 mt-md-0 product-features-heading">
              Product Features
            </h1>
            {/* <p className="text-center mt-3" id="desc-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum vulputate euismod. Morbi dui felis, posuere vel mollis dictum, varius sed nibh. Vestibulum lectus tortor, pellentesque quis viverra id, lobortis ac tellus. Sed dictum, nisl nec efficitur laoreet, massa ligula blandit justo, sed mattis nibh ex ut magna. Pellentesque id orci ex. Phasellus in porttitor nunc. Duis vitae ultricies ex.</p> */}
          </div>
          <div className="row mt-4">
            <div className="col-sm-6 col-xxl-6 d-flex justify-content-end">
              <img
                className="img-fluid"
                alt=""
                src="/assets/img/product_features/Group%20720.png"
              />
            </div>
            <div className="col-sm-6 col-xxl-6 d-flex justify-content-start">
              <img
                className="img-fluid"
                alt=""
                src="/assets/img/product_features/Group%20724.png"
              />
            </div>
            <div className="col-12 col-sm-6 d-flex justify-content-end">
              <img
                className="img-fluid"
                alt=""
                src="/assets/img/product_features/Group%20718.png"
              />
            </div>
            <div className="col-12 col-sm-6 d-flex justify-content-start">
              <img
                className="img-fluid"
                alt=""
                src="/assets/img/product_features/Group%20723.png"
              />
            </div>
          </div>
          <div className="mt-4 mt-md-5">
            <p className="text-center" id="desc-2">
              You may also rent Services, Events, and Equipment
            </p>
          </div>
        </div>
        <div
          className="container-fluid overflow-hidden carousel slide my-5"
          id="carousel-pages"
          data-ride="carousel"
          style={{ padding: "0px 0px" }}
        >
          <div id="background" className="div-carousel"></div>
          <div
            className="row p-3 mt-2 p-md-0 mt-md-5"
            id="row-carousel-container"
          >
            <div className="col-12 col-md-5 col-lg-4 col-xl-4 d-flex px-0">
              {/* Start of Carousel Nav */}
              <div
                className="flex-row flex-grow-1 align-items-center flex-md-column align-items-md-start overflow-auto px-3 pt-2 px-md-0 pt-md-0 carousel--list"
                id="nav-carousel"
              >
                <ul
                  className="nav nav-tabs text-nowrap d-flex flex-row flex-grow-1 flex-shrink-1 align-items-end flex-nowrap flex-md-column flex-md-grow-0 align-items-md-start mb-md-auto mb-0"
                  id="menu"
                >
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5 active-btn"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="0"
                    >
                      Download
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="1"
                    >
                      Browse through Listings
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="2"
                    >
                      Sign Up &amp; Complete Profile
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="3"
                    >
                      Inquire via Chat
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="4"
                    >
                      Ask for a Reservation
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="5"
                    >
                      Reservation Process
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="6"
                    >
                      Payment Process
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="7"
                    >
                      Complete the Transaction
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="8"
                    >
                      Rate and Review
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="9"
                    >
                      Create your Listing
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="10"
                    >
                      Ideal Listing Checklist
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="11"
                    >
                      Check Inquiries
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="12"
                    >
                      Accept or Reject Reservation
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="13"
                    >
                      Notified once the Payment has been Placed
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="14"
                    >
                      Payment Monitoring
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="15"
                    >
                      Deliver
                      <br />
                    </a>
                  </li>
                  <li className="nav-item d-grid gap-2 nav-item-sidebar">
                    <a
                      href="!#"
                      className="btn text-start btn-sidenav mb-md-1 py-md-1 ps-md-4 ps-lg-5"
                      role="button"
                      data-bs-target="#carousel-pages"
                      data-bs-slide-to="16"
                    >
                      Withdraw
                      <br />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
              <div className="row me-md-2 me-lg-5 mt-sm-2">
                <div className="col-12">
                  <h1 className="text-center text-md-end carousel-header">
                    How to Use the App
                  </h1>
                </div>
                <div className="col d-flex justify-content-center align-items-center justify-content-xxl-end min-vh-100">
                  <div className="card card-carousel w-100">
                    <div className="card-body d-sm-flex flex-fill justify-content-sm-center align-items-md-center">
                      <div className="d-flex flex-column flex-fill h-100">
                        <div
                          className="d-flex carousel-inner"
                          style={{ position: "relative", zIndex: "1" }}
                          role="listbox"
                        >
                          <div
                            id="1"
                            className="carousel-item active px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Download the Forent app via Google PlayStore or
                                App Store
                                <br />
                              </p>
                              <p>
                                You may proceed to sign up or skip to explore
                                the app first{" "}
                              </p>
                            </div>
                            <div
                              className="row d-flex d-sm-flex d-xl-flex justify-content-center me-auto justify-content-sm-center justify-content-lg-start"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-sm-5 col-md-6 col-lg-5 d-flex img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/download-1.png"
                                />
                              </div>
                              <div className="col-6 col-sm-5 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/download-2.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="2"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Explore the app, search and browse through
                                listings from our wide-range of
                                #EverythingRenting categories{" "}
                              </p>
                            </div>
                            <div
                              className="row d-flex d-xl-flex justify-content-center"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-sm-6 col-md-6 col-xl-5 col-xxl-4 d-flex d-md-inline d-lg-flex d-xl-flex d-xxl-flex justify-content-lg-center justify-content-xl-center justify-content-xxl-center align-items-xxl-center img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/browse-1.png"
                                />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 d-flex flex-sm-column flex-sm-fill container-2">
                                <div className="d-sm-flex flex-sm-column flex-sm-fill">
                                  <div>
                                    <p className="pt-sm-2 pt-lg-4 pt-xl-5">
                                      To easily find products or services you
                                      want to rent, the listings are categorized
                                      in the Home tab. You may choose among
                                      Vehicles, Staycations, Equipment, and the
                                      like.
                                    </p>
                                    <p className="pg-2 pt-sm-2 pt-lg-3">
                                      There is also a ‘Suggested for You’
                                      section to help you find listings that may
                                      be suitable for you.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="3"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Before you are able to inquire with Forent
                                Merchants, you must have an account. Sign up and
                                set up and verify your profile to get started.{" "}
                              </p>
                            </div>
                            <div
                              className="row justify-content-center"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-sm-5 col-md-5 col-lg-5 d-grid d-sm-grid img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/signup-1.png"
                                />
                              </div>
                              <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xxl-4 d-grid d-sm-flex align-items-sm-center img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/signup-2.png"
                                />
                              </div>
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 d-flex flex-sm-fill align-items-sm-start container-2 pt-sm-4">
                                <div className="d-sm-flex flex-sm-column flex-sm-fill">
                                  <div className="d-flex mt-auto">
                                    <img
                                      className="img-fluid"
                                      alt=""
                                      src="/assets/img/product_features/signup-3.png"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="4"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                When you find a listing you are interested to
                                rent, review the listing details and directly
                                inquire with the merchant via chat.{" "}
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/chat-1.png"
                                />
                              </div>
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/chat-2.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="5"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Tap button “Ask for a Reservation” to submit a
                                reserve request. Select your preferred date of
                                renting.{" "}
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/ask-1.png"
                                />
                              </div>
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/ask-2.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="6"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Wait for the merchant to accept your reservation
                                request. You can monitor your request’s status
                                via Messages tab or Activity tab.{" "}
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/reservation-1.png"
                                />
                              </div>
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/reservation-2.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="7"
                            className="carousel-item px-3 pt-3 mb-lg-5"
                            data-bs-interval="2000"
                            style={{ positon: "relative" }}
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Wait for the merchant to accept your reservation
                                request. You can monitor your request’s status
                                via Messages tab or Activity tab.{" "}
                              </p>
                            </div>
                            <div
                              className="row d-flex justify-content-center"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-sm-4 col-md-4 col-xl-4 col-xxl-4 d-flex justify-content-center align-items-center img-2">
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src="/assets/img/product_features/payment-1.png"
                                />
                              </div>
                              <div className="col-6 col-sm-4 col-md-4 col-xl-4 col-xxl-4 d-flex justify-content-center align-items-center img-2">
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src="/assets/img/product_features/payment-2.png"
                                />
                              </div>
                              <div className="col-6 col-sm-4 col-md-4 col-xl-4 col-xxl-4 d-flex justify-content-center align-items-center img-2">
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src="/assets/img/product_features/payment-3.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="8"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Wait for the merchant to accept your reservation
                                request. You can monitor your request’s status
                                via Messages tab or Activity tab.{" "}
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/complete-1.png"
                                />
                              </div>
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/complete-2.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="9"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Wait for the merchant to accept your reservation
                                request. You can monitor your request’s status
                                via Messages tab or Activity tab.{" "}
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex img-1">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/rate-1.png"
                                />
                              </div>
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/rate-2.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="10"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                &#8226; Read and Understand ther Terms and
                                Conditions. <br /> &#8226; Input all the
                                necessary details about your listings. <br />{" "}
                                &#8226; Post your Listings by clicking the{" "}
                                <b>Post Listng</b> button.
                              </p>
                            </div>
                            <div
                              className="row d-flex justify-content-center"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-sm-4 col-md-4 col-xl-4 col-xxl-4 d-flex justify-content-center align-items-center img-2">
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src="/assets/img/product_features/create-listing-1.png"
                                />
                              </div>
                              <div className="col-6 col-sm-4 col-md-4 col-xl-4 col-xxl-4 d-flex justify-content-center align-items-center img-2">
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src="/assets/img/product_features/create-listing-2.png"
                                />
                              </div>
                              <div className="col-6 col-sm-4 col-md-4 col-xl-4 col-xxl-4 d-flex justify-content-center align-items-center img-2">
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src="/assets/img/product_features/create-listing-3.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="11"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p></p>
                            </div>
                            <div
                              className="row d-flex d-xl-flex justify-content-center"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-sm-6 col-md-6 col-xl-5 col-xxl-4 d-flex d-md-inline d-lg-flex d-xl-flex d-xxl-flex justify-content-lg-center justify-content-xl-center justify-content-xxl-center align-items-xxl-center img-1 feature--img--2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/ideal-listing.png"
                                />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 d-flex flex-sm-column flex-sm-fill container-2">
                                <div className="d-sm-flex flex-sm-column flex-sm-fill">
                                  <div>
                                    <p className="pt-sm-2">
                                      <b>
                                        An Ideal listing should include the
                                        following:
                                      </b>
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Clear Photo, No logos, text or
                                      number
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Listing title
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Rate/price
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Location
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Product features or inclusions
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Rental method (For delivery, pick
                                      up, meet up)
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Availability on calendar
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Your own policy for cancellation
                                      and refund
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Your rules
                                    </p>
                                    <p className="pg-2 " style={{ color: "#" }}>
                                      &#8226; Contact number not to be included
                                    </p>
                                    {/* <p className="pt-sm-2" style={{color: "black"}}>Quick Tip!</p>
                                                                    <p className="pg-2 " style={{color: "black"}}>&#8226; Upload clear photos only to attract more Renters.</p>
                                                                    <p className="pg-2 " style={{color: "black"}}>&#8226; Refrain from adding your contact details in your listings</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="12"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                &#8226; Quick Tip <br /> You may use our in-app
                                chat feature to answer your Renter's inquiries.
                                It is also useful in tracking all the
                                conversations you had with them all throughout
                                the transaction
                              </p>
                            </div>
                            <div
                              className="row d-flex justify-content-center"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12 d-flex justify-content-center align-items-center img-2 feature--img">
                                <img
                                  alt=""
                                  className="img-fluid"
                                  src="/assets/img/product_features/inquiries.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="13"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Whenever a reservation request comes in, from a
                                Renter, you have the option to accept or reject
                                their request by clicking the "Accept" or
                                "Reject" Button{" "}
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/reservation.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="14"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                The status of the reservation will be changed to{" "}
                                <b>Payment Confirmed</b> once the Renter settled
                                the payment
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/notify.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="15"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                In the Payment Section, you can see your{" "}
                                <b>Available and Pending Balance.</b> You may
                                also set your preferred <b>Payout Method</b> in
                                this section.
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2 feature--img--2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/payment-monitoring.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="16"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>
                                Deliver the product/services on your agreed date
                                and location
                              </p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center img-2 feature--img--2">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="/assets/img/product_features/deliver.png"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="17"
                            className="carousel-item px-3 pt-3"
                            data-bs-interval="2000"
                          >
                            <div className="card-body-p px-3 py-1 px-lg-4 py-lg-2">
                              <p>Withdraw your payout after the transaction</p>
                            </div>
                            <div
                              className="row d-xl-flex"
                              style={{ margin: "0px 12px" }}
                            >
                              <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center img-2 feature--img--3">
                                <img
                                  className="img-fluid"
                                  alt=""
                                  style={{ height: "80%" }}
                                  src="/assets/img/product_features/withdraw.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-fill justify-content-center align-items-end justify-content-md-end btn-col pe-md-3 mb-3 mb-lg-0 carousel--cta">
                          <a
                            href="!#"
                            className="btn btn-carousel-page"
                            role="button"
                            data-bs-target="#carousel-pages"
                            data-bs-slide="prev"
                          >
                            <i className="fas fa-chevron-left"></i>
                          </a>
                          <a
                            href="!#"
                            className="btn btn-carousel-page"
                            role="button"
                            data-bs-target="#carousel-pages"
                            data-bs-slide="next"
                          >
                            <i className="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-hidden my-5 section-3">
          <div className="row px-2 px-md-5">
            <div className="col" id="Secure-Payment">
              <div>
                <h1 className="d-flex justify-content-center align-items-center secure-payment-heading">
                  <span className="me-3">
                    <img
                      className="img-fluid"
                      alt=""
                      src="/assets/img/product_features/lock.png"
                      width="23"
                      height="15"
                      style={{ padding: "1px" }}
                    />
                  </span>
                  Secure Payment
                </h1>
              </div>
            </div>
            <div className="col-12 col-sm-12 d-sm-flex">
              <div className="row d-sm-flex pb-5">
                <div
                  className="col-12 col-sm-12 col-lg-12 mt-2 mt-md-3"
                  id="Second-Column-1"
                >
                  <div className="row text-center d-sm-flex justify-content-sm-center justify-content-md-center ps-sm-4 ps-lg-5">
                    <div className="col-4 col-sm-4 col-md-4 col-lg-3 d-lg-flex justify-content-lg-center">
                      <div className="d-flex justify-content-center payment-logo-div">
                        <a
                          className="d-flex justify-content-center p-1 px-2"
                          href="https://www.gcash.com"
                        >
                          <img
                            className="img-fluid img-payment-logo"
                            alt=""
                            src="/assets/img/product_features/gcash.png"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-3 d-lg-flex justify-content-lg-center">
                      <div className="d-flex justify-content-center payment-logo-div">
                        <a
                          className="d-flex justify-content-center p-1 px-2"
                          href="https://www.paymaya.com"
                        >
                          <img
                            className="img-fluid img-payment-logo"
                            alt=""
                            src="/assets/img/product_features/paymaya.png"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-3 d-lg-flex justify-content-lg-center">
                      <div className="d-flex justify-content-center payment-logo-div p-1 px-2">
                        <a
                          className="d-flex justify-content-center p-1 px-2"
                          href="https://pay.weixin.qq.com/index.php/public/wechatpay"
                        >
                          <img
                            className="img-fluid img-payment-logo"
                            alt=""
                            src="/assets/img/product_features/wechat.png"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-sm-12 col-lg-12 mt-2 mt-md-3"
                  id="Second-Column"
                >
                  <div className="row text-center d-sm-flex justify-content-sm-center justify-content-md-center pe-sm-4 pe-lg-5">
                    <div className="col-4 col-sm-4 col-md-4 col-lg-3 d-lg-flex justify-content-lg-center">
                      <div className="d-flex justify-content-center payment-logo-div">
                        <a
                          className="d-flex justify-content-center p-1 px-2"
                          href="https://www.global.jcb/en/index.html"
                        >
                          <img
                            className="img-fluid img-payment-logo"
                            alt=""
                            src="/assets/img/product_features/jcb.png"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-3 d-lg-flex justify-content-lg-center">
                      <div className="d-flex justify-content-center payment-logo-div">
                        <a
                          className="d-flex justify-content-center p-1 px-2"
                          href="https://www.mastercard.com.ph/en-ph.html"
                        >
                          <img
                            className="img-fluid img-payment-logo"
                            alt=""
                            src="/assets/img/product_features/mastercard.png"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-3 d-lg-flex justify-content-lg-center">
                      <div className="d-flex justify-content-center payment-logo-div p-1 px-2">
                        <a
                          className="d-flex justify-content-center p-1 px-2"
                          href="https://www.visa.com.ph/pay-with-visa/click-to-pay-with-visa.html"
                        >
                          <img
                            className="img-fluid img-payment-logo"
                            alt=""
                            src="/assets/img/product_features/visa.png"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ProductFeatures;
