import React from "react";
// import { useNavigate } from "react-router-dom"
function Footer(props) {
  // const navigate = useNavigate();

  // const handleNavLocation = (event) => {
  //     event.preventDefault();
  //     // navigate(`/${event.target.dataset.location}`, { replace: true })
  //     // navigate(`/${event.target.dataset.location}`);
  //     // history.push("/"+event.target.dataset.location)

  // }
  return (
    <footer id="Footer" className="footer-clean mt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-4 col-md-3 item" id="Logo">
            <h3>
              <img
                className="img-fluid"
                src="/assets/img/logo%20v3.png"
                alt=""
              />
            </h3>
            <div className="row">
              <div
                className="col-6 col-xxl-5"
                style={{ margin: "auto", width: "180px", marginTop: "-10px" }}
              >
                <img
                  className="img-fluid"
                  src="/assets/img/new-desc.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-9 col-sm-4 col-md-3 item" id="About">
            <h3 style={{ color: "#F2B548" }}>About</h3>
            <ul>
              <li>
                <a href="/about" style={{ color: "#ffff" }}>
                  About Forent App
                  <br />
                </a>
              </li>
              <li>
                <a href="/" style={{ color: "#ffff" }}>
                  How it works
                </a>
              </li>
              <li>
                <a href="/" style={{ color: "#ffff" }}>
                  Our mission
                </a>
              </li>
              <li>
                <a href="/" style={{ color: "#ffff" }}>
                  FAQs
                </a>
              </li>
            </ul>
          </div>
          <div className="col-9 col-sm-4 col-md-3 item" id="Join-Forent">
            <h3 style={{ color: "#F2B548" }}>Join Forent</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#ffff" }}>
                  Become a Host
                </a>
              </li>
              <li>
                <a href="/" style={{ color: "#ffff" }}>
                  Advertise with Forent
                </a>
              </li>
              <li></li>
            </ul>
          </div>
          <div
            className="col-9 col-sm-4 col-md-3 col-lg-3 col-xl-3 item"
            id="Download-the-App-today"
          >
            <h3 style={{ color: "#F2B548" }}>Download the App today</h3>
            <div className="d-flex flex-lg-row">
              <a href="/redirect/android">
                <img
                  className="img-fluid"
                  src="/assets/img/footer/GooglePlayFooter.png"
                  alt=""
                  style={{ padding: "5px" }}
                  width="150"
                />
              </a>
              <a href="/redirect/ios">
                <img
                  className="img-fluid"
                  src="/assets/img/footer/AppStoreFooter.png"
                  alt=""
                  style={{ padding: "6px" }}
                  width="150"
                />
              </a>
            </div>
            <h3 style={{ color: "#F2B548", marginTop: "14px" }}>Follow Us</h3>
            <div className="d-flex flex-row justify-content-center justify-content-md-start">
              <a href="/redirect/fb">
                <img
                  className="img-fluid"
                  src="/assets/img/footer/Facebookbutton.png"
                  alt=""
                  width="60"
                  style={{ padding: "5px" }}
                />
              </a>
              <a href="/redirect/ig">
                <img
                  className="img-fluid"
                  src="/assets/img/footer/Instagrambutton.png"
                  alt=""
                  width="60"
                  style={{ padding: "5px" }}
                />
              </a>
            </div>
          </div>
          <div className="col-md-3 col-xl-2 col-xxl-2" id="2021-Forent-Group">
            <p style={{ width: "210px", color: "#ffff" }}>
              2021© Forent Group
              <br />
            </p>
          </div>
          <div
            className="col-md-3 col-xl-2"
            id="Terms-and-Condition"
            style={{ marginTop: "1px", marginBottom: "6px" }}
          >
            <ul>
              <li style={{ color: "#fff" }}>
                <a href="/terms-and-condition">Terms and Condition</a>
              </li>
            </ul>
          </div>
          <div className="col-md-2" id="Privacy-Policy">
            <ul>
              <li style={{ color: "#fff", marginBottom: "8px" }}>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="col" id="Community-Policy">
            <ul>
              <li style={{ color: "#fff" }}>
                <a href="/">Community Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
