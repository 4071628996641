import React from "react";
import {
  // useNavigate,
  NavLink,
  Link,
  useMatch,
} from "react-router-dom";
function Header(props) {
  // const navigate = useNavigate();

  // const handleNavLocation = (event) => {
  //     event.preventDefault();
  //     // navigate(`/${event.target.dataset.location}`, { replace: true })
  //     // navigate(`/${event.target.dataset.location}`);
  //     // history.push("/"+event.target.dataset.location)

  // }

  let bgHeader = "",
    logo = "",
    navlink = "",
    navlinkbtn = "",
    navcolor = "dark";
  const isBlogsRoute = useMatch("/blogs");
  const isArticleRoute = useMatch("/articles");
  if (isBlogsRoute) {
    bgHeader = "navbar-top-blog";
    logo = "/assets/img/blog/img/OrangeForentLogo.png";
    navlink = "nav-link-top-blog";
    navlinkbtn = "navbar-download-color-blog";
    navcolor = "light";
  } else if (isArticleRoute) {
    bgHeader = "navbar-top-article";
    logo = "/assets/img/blog/img/OrangeForentLogo.png";
    navlink = "nav-link-top-blog";
    navcolor = "light";
    navlinkbtn = "navbar-download-color-blog";
  } else {
    bgHeader = "navbar-top";
    navlink = "nav-link-top";
    logo = "/assets/img/logo-white.png";
    navlinkbtn = "navbar-btn";
    navcolor = "dark";
  }
  return (
    <nav className={"navbar navbar-expand-md navbar-" + navcolor} id={bgHeader}>
      <div className="container-fluid">
        <a className="navbar-brand m-3 ms-md-4" href="/">
          <img alt="" src={logo} style={{ width: "88px", height: "70px" }} />
        </a>
        <button
          data-bs-toggle="collapse"
          className="navbar-toggler"
          data-bs-target="#navcol-1"
        >
          <span className="visually-hidden">Toggle navigation</span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse d-lg-flex justify-content-lg-center align-items-lg-center pb-2 pb-md-0"
          id="navcol-1"
        >
          <ul className="navbar-nav" style={{ visibility: "initial" }}>
            <li className="nav-item text-md-center py-1 px-lg-4">
              <NavLink to="/" className={"nav-link px-md-2 active " + navlink}>
                Home
              </NavLink>
            </li>
            <li className="nav-item text-md-center py-1 px-lg-4">
              <NavLink to="/about" className={"nav-link px-md-2 " + navlink}>
                About Forent
              </NavLink>
            </li>
            <li className="nav-item text-md-center py-1 px-lg-4">
              <NavLink
                to="/product-features"
                className={"nav-link px-md-2 " + navlink}
              >
                Product Features
              </NavLink>
            </li>
            <li className="nav-item text-md-center py-1 px-lg-4">
              <NavLink to="/blogs" className={"nav-link px-md-2 " + navlink}>
                Blogs
              </NavLink>
            </li>
            {/* <li className="nav-item text-md-center py-1 px-lg-4">
                            <NavLink to="/become-a-merchant" className="nav-link px-md-2 nav-link-top">
                                Become a Merchant
                            </NavLink>
                        </li> */}
            {/* <li className="nav-item dropdown py-1 px-lg-4">
                            <a className="dropdown-toggle nav-link d-flex align-items-center px-md-2 nav-link-top" aria-expanded="false" data-bs-toggle="dropdown" href="!#">More<i className="fas fa-chevron-down fs-6 ms-1" style={{fontSize: '1.125rem'}}></i></a>
                            <div className="dropdown-menu">
                                <NavLink to="/product-features" className="dropdown-item" 
                                    style={({ isActive }) => ({
                                        color: isActive ? '#F15D3E' : '#000',
                                        fontWeight: isActive ? '600' : '400',
                                        background: isActive ? '#fff' : '#fff',
                                    })}
                                >
                                    Product Features
                                </NavLink>
                                <NavLink to="/advertise-with-us" className="dropdown-item" 
                                    style={({ isActive }) => ({
                                        color: isActive ? '#F15D3E' : '#000',
                                        fontWeight: isActive ? '600' : '400',
                                        background: isActive ? '#fff' : '#fff',
                                    })}
                                >
                                    Advertise With Us
                                </NavLink>
                                <NavLink to="/faqs" className="dropdown-item" 
                                    style={({ isActive }) => ({
                                        color: isActive ? '#F15D3E' : '#000',
                                        fontWeight: isActive ? '600' : '400',
                                        background: isActive ? '#fff' : '#fff',
                                    })}
                                >
                                    FAQs
                                </NavLink>
                                <a className="dropdown-item" href="!#">Last Item</a></div>
                        </li> */}
          </ul>
          <a
            className="btn btn-primary ms-auto my-1 d-flex justify-content-center align-items-center"
            id={navlinkbtn}
            type="button"
            style={{ visibility: "initial", float: "left" }}
            href="https://forent.page.link/fb_forent"
            target={"_blank"}
          >
            Download App
          </a>
        </div>
      </div>
    </nav>
  );
}
export default Header;
