import React, { Component } from "react";
import { analyticsLogEvent } from "../App";

class BecomeAMerchant extends Component {
  componentDidMount(){
    analyticsLogEvent('webapp_become_merchant_page');
  }
  render() {
    return (
      <div className="container mb-5">
        <div className="row mt-5 mx-2 mx-md-3 mx-lg-5" id="merchant-row-1">
          <div className="col-12 col-md-6 col-xl-7 mb-5">
            <div className="pe-lg-5">
              <p id="merchant-heading">Expand your Renting Business</p>
              <p className="merchant-paragraph mt-4">
                <strong>Become a legit merchant. </strong>Reach more client with
                us. Be one with the safe platform for Everything Renting with
                verification and security. Lorem ipsum dolor sit amet
              </p>
              <p id="merchant-paragraph-2" className="mt-5 mb-3">
                Register and start listing for <strong>FREE</strong>
              </p>
              <button className="btn" id="merchant-btn" type="button">
                Sign-Up
              </button>
            </div>
          </div>
          <div className="col-md-6 col-xl-5 d-sm-flex d-md-flex justify-content-center justify-content-sm-center align-items-md-start justify-content-lg-end justify-content-xxl-end pb-5">
            <img
              className="img-fluid"
              alt=""
              src="/assets/img/Group%20804.png"
            />
          </div>
        </div>
        <div className="row mt-5 mx-2 mx-md-3 mx-lg-5" id="merchant-row-2">
          <div className="col-md-6 col-xl-5 col-xxl-5 d-sm-flex d-md-flex justify-content-center order-2 justify-content-sm-center align-items-md-start justify-content-lg-start align-items-lg-start justify-content-xxl-start pb-5">
            <img
              className="img-fluid"
              alt=""
              src="/assets/img/Group%20804.png"
            />
          </div>
          <div className="col-12 col-md-6 col-xl-7 order-md-2 mb-5 ps-lg-5">
            <p id="merchant-heading-2">Create a Listing&nbsp;</p>
            <p className="merchant-paragraph mt-4">
              <strong>Anyone can be a merchant.&nbsp;</strong>Forent App is open
              to anyone who want to rent out products, equipment, or services.
              Everyone who downloads the app and sign-up are required to verify
              their identity as our way to make Forent community a safer
              platform free from scams.&nbsp;
            </p>
            <p className="merchant-paragraph mt-5">
              You can be a merchant&nbsp; by creating a listings. For merchants
              with government registered rental business/es, you can request
              special verification to earn our Legit Merchant badge.&nbsp;
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default BecomeAMerchant;
