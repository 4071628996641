import React, { Component } from "react";
import { analyticsLogEvent } from "../App";

export class RedirectUrl extends Component {
  constructor(props){
    super();
  }
  componentDidMount(){
    const {href} = this.props
    if(href === '/download/ios'){
        analyticsLogEvent('webapp_click_download_ios')
        window.location = 'https://apps.apple.com/ph/app/forent/id1591452773';
    } else if (href === '/download/android'){
        analyticsLogEvent('webapp_click_download_android')
        window.location = 'https://play.google.com/store/apps/details?id=com.ph.forent_app&amp;hl=en&amp;gl=US';
    } else if (href === '/fb') {
        analyticsLogEvent('webapp_visit_social_page_fb')
        window.location = 'https://www.facebook.com/ForentSuperApp'
    } else if (href === '/ig'){
        analyticsLogEvent('webapp_visit_social_page_ig')
        window.location = 'https://www.instagram.com/forent_super_app'
    } else {
        window.location = '/'
    }
  }
  render(){
    return (
    <div style={{height: "700px", paddingTop: "50px"}}>
        <div className="d-flex justify-content-center">
        <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p style={{padding: "5px 10px 10px 10px"}}>Redirecting...</p>
        </div>
    </div>
    );
  }
}
export default RedirectUrl;