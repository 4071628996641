import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { analyticsLogEvent } from "../App";

class About extends Component {
  componentDidMount(){
    analyticsLogEvent('webapp_about_page');
  }
  render() {
    return (
      <div>
        <Helmet>
          <body data-bg-about="true" />
        </Helmet>

        <div className="container-fluid section-1-bg mx-0 p-0 mt-3 pb-5">
          <div className="section-1-text p-3 p-md-5 pb-md-1 mx-4 mx-md-5">
            <p id="about-us">About us</p>
            <h1 id="about-heading-1" className="mb-4">
              We’re creating a world where renting is easy as 1,2,3
            </h1>
            <p className="about-paragraph-1 mt-3">
              Here’s the harsh reality: most Filipinos who go online to rent or
              have an item rented, get scammed. Sounds familiar? Whether you’re
              a renter who’s got their payment unwittingly stolen or a lessor
              who’s experienced the pain of having to deal with a bogus buyer,
              we’ve been there. The rental industry is a nuisance at this point—
              but not anymore.
              <br />
            </p>
            <p className="about-paragraph-1 mt-4">
              Forent is a one-stop shop for all things rent. It is a safe,
              reliable space where lessors and renters can come together to
              enjoy each other’s company. Experience what you want to
              experience, without the hassle. Gone are the days where renters
              needed to scout through unreliable online platforms to look for
              items to rent: now everything is at their fingertips through the
              Forent app. Renters and lessors now have the assurance of safe and
              legit transactions, anywhere, anytime.
              <br />
            </p>
          </div>
          <div className="d-xl-flex justify-content-xl-center mt-5">
            <img
              className="img-fluid"
              alt=""
              src="/assets/img/about/IMG_0545%201.png"
            />
          </div>
        </div>
        <div className="container-fluid mt-5 p-0">
          <div className="section-2-div">
            <div className="row g-0 py-5 px-2">
              <div className="col-12 col-sm-12 col-md-6 col-lg-5 ps-md-5">
                {/* <div id="section-2-card" className="m-5"></div> */}
              </div>
              <div className="col p-0">
                <div className="section-2-text p-3 ps-md-0 pe-md-5 py-md-5 pb-md-1 mx-4 mx-md-0">
                  <h1 id="our-story">Our Story</h1>
                  <div className="mt-4 mb-5 me-md-3 me-lg-5">
                    <p className="story-paragraph-2">
                      It all started a few months ago when the Co-Founder and
                      CEO, Mr. Jayvee De Leon, thought of building a platform to
                      help our fellow kababayans who were continuously being
                      scammed at different online platforms looking for items to
                      rent. Wanting to challenge the status quo, he then went
                      out to develop the Forent Super App.
                      <br />
                    </p>
                    <p className="story-paragraph-2 mt-4">
                      Today, our team works to provide our consumers with
                      excellent service and an enjoyable environment. We, at
                      Forent, aim to make the rental industry in the country a
                      safe, reliable, and memorable experience for everyone.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container d-flex justify-content-center justify-content-lg-end pe-lg-5" id="section-3">
                    <div id="section-3-card" className="mx-3 mx-md-0">
                        <div className="text-end section-3-text p-4 p-md-5 mb-sm-3">
                            <h1 id="our-mission">Our mission</h1>
                            <p id="mission-paragraph-3">Forent will become a catalyst in transforming the rental community by<br/>(1) creating a safe and collaborative space for lessors and renters<br/>(2) providing secured and seamless transactions<br/>(3) and operating as the super application for everything renting</p>
                        </div>
                    </div>
                </div> */}
        <div className="container-fluid mt-5 mx-0 p-0 pb-5" id="section-4">
          <div className="section-4-text p-3 p-md-5 pb-md-1 mx-4 mx-md-5">
            <h1 id="section-4-heading">Values and Culture </h1>
            <p id="values-paragraph-4" className="mt-5">
              Our team is one big, happy family continuously working towards
              improving your renting experience. We believe in teamwork,
              collaboration, and innovation. Forent believes in cultivating
              long-lasting relationships with its users, both businesses and
              consumers. We do this by creating and innovating a warm, safe, and
              friendly environment to help businesses scale their products and
              services; and for consumers to have a reliable platform for all
              their renting needs.{" "}
            </p>
          </div>
        </div>
        <div className="container-fluid" id="section-5">
          <div id="section-5-bg"></div>
          <div className="row d-sm-flex justify-content-sm-center section-5-body">
            <div className="col-sm-9 col-md-7 col-lg-6 col-xl-6 col-xxl-5 d-xl-flex justify-content-xl-end">
              <img
                className="img-fluid"
                alt=""
                src="/assets/img/about/Mock-ups.png"
              />
            </div>
            <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-7 p-0">
              <div className="p-3 ps-lg-0 pe-lg-5 py-lg-5 mx-4 mx-lg-0 mt-lg-0 mt-xl-5 mb-5">
                <h1 id="section-5-heading">Experience Forent</h1>
                <p className="experience-paragraph-5 mt-4 me-md-3 me-lg-5">
                  Forent has a wide range of items for rent: from clothes to
                  accessories, vehicles, and even properties! We offer zero
                  listing and monthly subscription fees to help lessors in the
                  rental business reach more customers. Rent whatever you want
                  to experience, or share that wonderful experience with your
                  customers as a lessor!
                </p>
                <p className="experience-paragraph-5 mt-4 me-md-3 me-lg-5">
                  Forent App is currently available for download at The App
                  Store and on Google Play.
                </p>
                <button className="btn mt-3" id="section-5-btn" type="button">
                  Download App
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="section-6">
          <div className="row p-3 p-md-5 pb-md-1 mx-3 mx-md-4">
            <div
              className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start px-md-3"
              id="col-1"
            >
              <div id="circle-1" className="section-6-circle"></div>
            </div>
            <div
              className="col-12 col-md-4 text-center text-md-start order-md-4 mt-4 px-md-3"
              id="col-2"
            >
              <h1 className="section-6-heading">Product Features</h1>
            </div>
            <div
              className="col-12 col-md-4 mt-2 mb-5 ps-md-3 pe-md-5"
              id="col-3"
            >
              <p className="text-center text-md-start section-6-paragraph">
                Explore the app, explore Forent’s wide range of categories of
                what you want or need to rent&nbsp;
                <i className="fas fa-arrow-right"></i>
              </p>
            </div>
            <div
              className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start order-md-2 px-md-3"
              id="col-4"
            >
              <div id="circle-2" className="section-6-circle"></div>
            </div>
            <div
              className="col-12 col-md-4 text-center text-md-start order-md-5 mt-4 px-md-3"
              id="col-5"
            >
              <h1 className="section-6-heading">How it Works</h1>
            </div>
            <div
              className="col-12 col-md-4 mt-2 mb-5 ps-md-3 pe-md-5"
              id="col-6"
            >
              <p className="text-center text-md-start section-6-paragraph">
                See how to use the app and how to get started&nbsp;
                <i className="fas fa-arrow-right"></i>
              </p>
            </div>
            <div
              className="col-md-4 d-flex justify-content-center justify-content-md-start order-md-3 px-md-3"
              id="col-7"
            >
              <div id="circle-3" className="section-6-circle"></div>
            </div>
            <div
              className="col-12 col-md-4 text-center text-md-start mt-4 px-md-3"
              id="col-8"
            >
              <h1 className="section-6-heading">Become a legit merchant</h1>
            </div>
            <div className="col-md-4 mt-2 mb-5 ps-md-3 pe-md-5" id="col-9">
              <p className="text-center text-md-start section-6-paragraph">
                Register and enlist! Start growing your business and reaching
                more customers through Forent&nbsp;
                <i className="fas fa-arrow-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default About;
