import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { analyticsLogEvent } from "../App";

class AdvertiseWithUs extends Component {
    componentDidMount(){
        analyticsLogEvent('webapp_advertise_page')
    }
    render (){
        return (
            <div className="container pt-5 mb-5">
                <Helmet>
                    <style>{'body { background-color: #f15d3e; }'}</style>
                </Helmet>

                <div className="row mt-5 mx-2 mx-md-3 mx-lg-5" id="merchant-row-2">
                    <div className="col-md-6 col-xl-5 col-xxl-5 d-sm-flex d-md-flex justify-content-center order-2 justify-content-sm-center justify-content-md-end align-items-md-start justify-content-lg-end align-items-lg-start justify-content-xxl-end pb-5">
                        <img className="img-fluid" alt="" src="/assets/img/Group%20804.png"/></div>
                    <div className="col-12 col-md-6 col-xl-7 order-md-2 mb-5 ps-lg-5">
                        <p id="advertise-heading">Advertise inside the Forent SuperApp</p>
                        <p id="advertise-paragraph" className="mt-4"><br/>Lorem ipsum dolor sit amet. Cum quasi voluptate vel autem aliquid ut voluptatem aperiam et delectus voluptate. Quo temporibus molestiae et suscipit perspiciatis eum autem galisum. Aut deserunt quia qui reprehenderit error est praesentium ipsam rem nihil accusantium non temporibus suscipit et asperiores dolore qui officia impedit.<br/><br/></p>
                    </div>
                </div>
            </div>
        )
    }
}
export default AdvertiseWithUs;