import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { analyticsLogEvent } from "../App";

class FAQs extends Component {
    componentDidMount(){
        analyticsLogEvent('webapp_faqs_page')
    }
    render (){
        return (
            <div id="faqs-bg">
                <Helmet>
                    <style>{'body { background-color: #f2b54b; }'}</style>
                </Helmet>
                
                <h1 id="faq-heading" className="p-4 mt-5 mb-3 mx-3 mx-md-5">Frequently Asked Questions</h1>
                <div className="d-flex justify-content-center">
                    <div className="accordion p-md-5 mx-md-5 w-75" role="tablist" id="accordion-1">
                        <div className="accordion-item px-2 px-md-3 p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-1" aria-expanded="false" aria-controls="accordion-1 .item-1">What is Forent?</button></h2>
                            <div className="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-2" aria-expanded="false" aria-controls="accordion-1 .item-2">How do I inquire/avail of the products or services?</button></h2>
                            <div className="accordion-collapse collapse item-2" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-3" aria-expanded="false" aria-controls="accordion-1 .item-3">As a customer/renter, how will I ensure that my payment is safe until I use the products or services that I avail?</button></h2>
                            <div className="accordion-collapse collapse item-3" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-4" aria-expanded="false" aria-controls="accordion-1 .item-4">What are the forms of payment that I can use?</button></h2>
                            <div className="accordion-collapse collapse item-4" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Customers/Renters can pay using their Credit cards, Debit Cards and E-wallets (GCash and Paymaya)<br/><br/>All payments should be done within the Forent App for safe and secure transactions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-5" aria-expanded="false" aria-controls="accordion-1 .item-5">What if I need to cancel my reservation?</button></h2>
                            <div className="accordion-collapse collapse item-5" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-6" aria-expanded="false" aria-controls="accordion-1 .item-6">When can I rate and review the merchant or renter?<br/></button></h2>
                            <div className="accordion-collapse collapse item-6" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-7" aria-expanded="false" aria-controls="accordion-1 .item-7">As a Merchant/Lessor, is there a listing fee?<br/></button></h2>
                            <div className="accordion-collapse collapse item-7" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-8" aria-expanded="false" aria-controls="accordion-1 .item-8">As a Merchant/Lessor, is there a service fee?<br/></button></h2>
                            <div className="accordion-collapse collapse item-8" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item p-2 p-md-3 mb-3">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-9" aria-expanded="false" aria-controls="accordion-1 .item-9">How can I earn the badge and be a Forent legit merchant?<br/></button></h2>
                            <div className="accordion-collapse collapse item-9" role="tabpanel" data-bs-parent="#accordion-1">
                                <div className="accordion-body">
                                    <p className="mb-0">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center py-5">
                    <p id="faqs-p" className="w-75">If you have other inquiries or concerns you may&nbsp;reach us at&nbsp;<a id="faqs-link" href="!#">info@forent-app.com</a></p>
                </div>
            </div>
        )
    }
}
export default FAQs;