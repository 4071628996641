import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { analyticsLogEvent } from "../App";

class PrivacyPolicy extends Component {
  componentDidMount(){
    analyticsLogEvent('webapp_privacy_policy_page')
  }
  render() {
    return (
      <div class="container pb-5 px-5">
        <Helmet>
          <style>{"body { background-color: #ffffff; }"}</style>
        </Helmet>

        <div className="w-50 mb-5" style={{ marginTop: "55px" }}>
          <img className="img-fluid" alt="" src="/assets/img/logo%20v3.png" />
        </div>
        <div className="pb-5">
          <p className="policy-body mb-0">
            1. Privacy Policy
            <br />
            1.1 We take your privacy very seriously and are committed to
            protecting the privacy of all visitors and users to our mobile
            application, website and the corresponding services available
            through the mobile application and website.
            <br />
            Below we set out our privacy policy, which will govern the way in
            which we process any personal information that you provide to us.
            <br />
            This privacy policy applies only to our mobile application, website
            and/or services and not to applications or website of any other
            companies or organizations. This Privacy policy is designed to
            assist you in understanding how we collect, use, disclose and/or
            process the personal data you have provided to us and/or we possess
            about you, whether now or in the future, as well as to assist you in
            making an informed decision before providing us with any of your
            personal data. This privacy policy supplements other notices and
            privacy policies is not intended to override them.
            <br />
            <br />
            1.2 “Personal data” or “personal data” means data, whether true or
            not, about an individual who can be identified from that data, or
            from that data and other information to which an organization has or
            is likely to have access. Example of personal data could include
            name, identification number and contact information.
            <br />
            <br />
            1.3 By using the services, registering for an account with us,
            visiting our platform, or accessing the services, you acknowledge
            and agree that you accept the requirements, and/or policies outlined
            in this privacy policy, and you hereby consent to us collecting,
            using, disclosing and/or processing your personal data as described
            herein. IF YOU DO NOT CONSENT TO THE PROCESSING OF YOUR PERSONAL
            DATA AS DESCRIBED IN THIS PRIVACY POLICY. PLEASE DO NOT USE OUR
            SERVICES AND PLATFORM.
            <br />
            <br />
            1.4 This policy applies to both customers and merchants who use the
            services except where expressly stated otherwise.
            <br />
            <br />
            2. WHEN WILL FORENT COLLECT PERSONAL DATA
            <br />
            2.1 We will/may collect personal data about you:
            <br />
          </p>
          <ul className="policy-body policy-list">
            <li>
              When you register and/or use our services or platform, or sign up
              an account with us;
            </li>
            <li>
              When you submit any form, including, but not limited to,
              application forms or other forms relating to any of our products
              and services, whether online or by way of a physical form;
            </li>
            <li>
              When you enter to any agreement or provide other documentation or
              information in respect of your interaction with us, or when you
              use our products and services;
              <br />
            </li>
            <li>
              When you interact with us, such as via email, face-to-face
              meetings, virtual meetings, social media platforms, Telephone
              calls (Which may be recorded) and when you interact with our
              customer service representatives;
            </li>
            <li>
              When you use our electronic services, or interact with us via our
              mobile application or use services on our platform. This includes,
              without limitation, through cookies which we may deploy when you
              interact with our mobile application or website;
            </li>
            <li>
              When you grant permission on your device to share information with
              our mobile application or platform;
            </li>
            <li>
              When you link your Forent account with your social media or other
              external account or use other social media features;
            </li>
            <li>
              When you carry out transactions though our services and platform;
            </li>
            <li>When you provide us feedback or complaints;</li>
            <li>When you register for a promo;</li>
            <li>When you submit your personal data to us for any reason</li>
          </ul>
          <p className="policy-body mb-0">
            3. WHAT PERSONAL DATA WILL FORENT COLLECT
            <br />
            3.1 The personal data that Forent will/may collect includes but not
            limited to:
          </p>
          <ul className="policy-body policy-list">
            <li>Name;</li>
            <li>Email address</li>
            <li>
              Date of birth
              <br />
            </li>
            <li>Bank account and payment or payout information</li>
            <li>Contact number, Telephone or cellphone</li>
            <li>Gender</li>
            <li>
              Information sent by or associated with the device(s) used to
              access our services or platform;
            </li>
            <li>
              Information about your network and the people and accounts you
              interact with;
            </li>
            <li>
              Photographs, audio, video recordings that you share with us;
            </li>
            <li>
              Government issued identification or other information required for
              our due diligence, Know your customer, identity verification or
              fraud prevention purposes;
            </li>
            <li>
              Marketing and communications data, such as your preferences in
              receiving marketing from us and third parties
            </li>
            <li>
              Usage and transaction data, including details about your searches,
              the advertising and content you interact with on the platform, and
              other products and services related to you;
            </li>
            <li>Location data;</li>
            <li>
              Any other information about the user when user signs up to use our
              services and platform, as well as information related to how the
              user uses our services and platform
            </li>
            <li>
              Aggregate data on content the user engages with.
              <br />
            </li>
          </ul>
          <p className="policy-body mb-0">
            3.2 You agree not to submit any information to us which is
            inaccurate or misleading, and you agree to inform us of any
            inaccuracies or changes to such information. We reserve the right at
            our sole discretion to require further documentation to verify the
            information provided by you.
            <br />
            <br />
            3.3 If you sign up to be a user of our platform using your social
            media account, link your social media account to Forent or use any
            Forent social media features, we may access information about you
            which you have voluntarily provided to your social media account
            provider in accordance with such provider’s policies, and we will
            manage and use any such personal data in accordance with this policy
            at all times.
            <br />
            <br />
            3.4 If you do not want us to collect the aforementioned
            information/personal data, you may opt out at any time by notifying
            us in writing. Note, however that opting out or withdrawing your
            consent for us to collect, use or process your personal data may
            affect your use of the service and the platform
            <br />
            <br />
            4. COLLECTION OF OTHER DATA
            <br />
            4.1 As with most mobile applications and websites, your device sends
            information which may include data about you that gets logged by a
            web server when you open or use our platform. This normally includes
            without limitation your device’s Internet Protocol (IP) address,
            Mobile/Computer device operating system and browser type, type of
            mobile device, the unique device identifier or mobile equipment
            identifier for your mobile device, the address of a referring
            website (if any), the pages you visit on our website and mobile
            applications and the times of visit, and sometimes a “cookie” to
            help the site remember your last visit. If you are logged in, this
            information is associated with your personal account. The
            information is also included in anonymous statistics to allow us
            understand how visitors use our site
            <br />
            <br />
            4.2 Our mobile application may collect precise information about the
            location of your mobile device using technologies such as GPS,
            Wi-Fi, etc. We collect, use, disclose and/or process this
            information for one or more purposes including but not limited to;
            when you allow to share your location to other users as part of the
            services under our mobile applications. If you have questions about
            how to disable your mobile device’s location services, please
            contact your mobile device service provider or the device
            manufacturer
            <br />
            <br />
            4.3 When you view pages on our mobile application or website, when
            you watch content and advertising and access other software on our
            platform or through the services, most of the information is sent to
            us including but not limited to, IP Address, operating system, etc.
            <br />
            <br />
            5. COOKIES
            <br />
            5.1 We/or our authorized service providers and advertising partners
            may from time to time use “cookies” or other features to allow us or
            third parties to collect or share information in connection with
            your use of our services or platform. These feature help us improve
            our platform and the services we offer, help us offer new services
            and features to users. “Cookies” are identifiers that are stored on
            your computer or mobile that record data about computer or device,
            how and when our services or platform are used or visited, by how
            many people and other activity within our platform.
            <br />
            <br />
            5.2 You may refuse the use of cookies by selecting the appropriate
            settings on your browser or device. However, Please note that if you
            do this you may not able to use the full functionality of our
            platform or services.
            <br />
            <br />
            6. HOW DO WE USE THE INFORMATION YOU PROVIDE TO US?
            <br />
            6.1 We may collect, use, disclose and/or process your personal data
            for any of the following purposes:
          </p>
          <ul className="policy-body policy-list">
            <li>
              To consider and/or process your application/transaction with us or
              your transactions or communications with third parties via the
              services;
            </li>
            <li>
              To manage, operate, provide and/or administer your use of and/or
              access to our services and our platform including but not limited
              to, remembering your preference, as well as your relationship and
              user account with us
            </li>
            <li>
              To respond to, process, deal with or complete a transaction and/or
              fulfill your requests for certain products and services and notify
              you of service issues and unusual account actions;
              <br />
            </li>
            <li>
              To enforce our terms and condition or any applicable end user
              license agreement;
            </li>
            <li>
              To protect personal safety and the rights, property of safety of
              others; For identification, verification, due diligence, or know
              your customer purposes
            </li>
            <li>
              To maintain and administer any software updates and/or other
              updates to support that may be required from time to time to
              ensure smooth running of our services and platform;
              <br />
            </li>
            <li>
              To deal with or facilitate customer service, deal with or respond
              to any inquiries given by you or your behalf
            </li>
            <li>
              To contact you or communicate with you via voice call, text
              message and/or fax message, email and/or postal mail or otherwise
              for the purposes of administering and/or managing your
              relationship with us or your use of our services and platform.
            </li>
            <li>
              To allow other user to interact, connect with you or see some of
              your activities on the platform. Including to inform you when
              other user has sent you a message, posted a rating or review about
              you on the platform or connected with you using the social
              features on the platform.
            </li>
            <li>
              To conduct research, analysis and development activities including
              but not limited to, data analytics, surveys, products and services
              development, to improve our services and/or enhance your customer
              experience;
            </li>
            <li>
              To allow for audits to, among other things, validate the size and
              composition of our target audience, and understand their
              experience with our services; For marketing and advertising. To
              send you various mediums and modes of communication marketing and
              promotional information and materials relating to products and/or
              services including but not limited to products and/or services of
              third parties whom Forent may partner or tie up with. We may use
              your contact information to send newsletters or marketing
              materials from us and from our partner or tie up companies. You
              can unsubscribe from receiving marketing information at any time
              by clicking the unsubscribe button;
            </li>
            <li>
              To respond to legal processes or to comply with or as required by
              any applicable law, governmental or regulatory requirements of any
              relevant jurisdiction.
            </li>
            <li>
              To produce statistics and research for internal and statutory
              reporting and/or record keeping requirements;
            </li>
            <li>
              To carry out due diligence or other screening activities including
              but not limited to background checks, in accordance with legal or
              regulatory obligations or our risk management procedures that may
              be required by law or that may have been put in place by us;
            </li>
            <li>
              To prevent or investigate any actual or suspected violations of
              our terms and conditions, fraud, unlawful activity, omission or
              misconduct, whether relating to your use of our service or
              platform or any other matter arising from your relationship with
              us;
              <br />
            </li>
            <li>
              To respond to any threatened or actual claims asserted against
              Forent or other claim that any content violates the rights of
              third parties
              <br />
            </li>
            <li>
              To store, host, back up, (whether for disaster recovery or
              otherwise) of your personal data, whether within or outside of
              your jurisdiction;
            </li>
            <li>
              To deal with and/or facilitate a business asset transaction or a
              potential business asset transaction. Where such transaction
              involves Forent as a participant or involves only a related
              corporation or affiliate of Forent as a participant. “Business
              asset transaction” refers to the purchase, sale, lease, merger,
              amalgamation or any other acquisition, disposal or financing of an
              organization or a portion of an organization or of any of the
              business or assets of an organization; and/or Any other purposes
              which we notify you at the time of obtaining your consent.
            </li>
          </ul>
          <p className="policy-body mb-0">
            6.2 You acknowledge, consent and agree that Forent may access,
            preserve and disclose your account information and content if
            required to do so by law or pursuant to an order of a court or by
            any government or regulatory authority having jurisdiction over
            Forent or in a good faith belief that such access preservation or
            disclosure is reasonably necessary to: Comply with legal process,
            comply with a request from any government or regulatory authority
            having jurisdiction over Forent, enforce the Forent terms and
            conditions or this privacy policy, respond to any threatened or
            actual claims asserted against Forent or other claim that any
            content violates the rights of third parties, respond to your
            request for customer service or protect the rights, property or
            personal safety of Forent, its users and/or public
            <br />
            <br />
            6.3 This information is required to provide the services to you. If
            you do not provide such information, it may delay or prevent us from
            providing the services.
            <br />
            <br />
            7. WHO WE SHARE YOUR INFORMATION WITH:
            <br />
            7.1 Your personal information (which includes your name, Identity
            verifier, address and any other details you provided to us) may be
            process by Us.
            <br />
            We may also share your information with:
          </p>
          <ul className="policy-body policy-list">
            <li>
              Third parties we use to help deliver our services to you (For
              example, Payment service providers);
            </li>
            <li>
              Other third parties we use to help us run our business (For
              example, marketing agencies or website host)
            </li>
            <li>
              Third parties approved by you (For example, social media sites you
              choose to link your account o or third party payment providers)
              <br />
            </li>
            <li>
              Merchants or other users you have transacted with or interacted
              with on the platform or in connection with your use of the service
              or platform
            </li>
            <li>
              Government or regulatory authorities having jurisdiction over
              Forent
            </li>
            <li>
              A buyer or other successor in the event of a merger, divestiture,
              reconstructing, reorganization, dissolution or other sale or
              transfer of some or all of Forent’s asset. Whether as a going
              concern or as part of bankruptcy, liquidation or similar
              proceeding, in which personal data held by Forent about our
              service users is among the assets transferred; or to a
              counterparty in a business asset transaction that Forent or any of
              its affiliates or corporations is involved in; and
              <br />
            </li>
            <li>
              Third parties to whom disclosure by Us is for one or more of the
              purposes and such third parties would in turn be collecting and
              processing your personal data for one or more purposes
            </li>
          </ul>
          <p className="policy-body">
            7.2 We may share user information, including demographic
            information, about our users and information about their use of the
            services with advertising partners and third party suppliers of
            advertisements, remarketing, and/or other programming
            <br />
            <br />
            7.3 In the event that privacy laws or other applicable laws permit
            an organization such as Us to collect, use or disclose your personal
            data without consent, such permission granted by the laws shall
            continue to apply. Consistent with the foregoing and subject to
            applicable law, we may use your personal data for recognized legal
            grounds including to comply with our legal obligations, to perform
            our contract with you, to achieve a legitimate interest and our
            reasons for using it outweigh any prejudice to your data protection
            rights, or where necessary in connection with a legal claim
            <br />
            <br />
            7.4 We require all third parties to respect the security of your
            personal data and to treat in accordance with the law. We do not
            allow our third party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
            However;
            <br />
            <br />
            7.5 Third parties may unlawfully intercept or access personal data
            transmitted to or contained on the mobile application or website,
            technologies may malfunction or not work as anticipated, or someone
            might access, abuse, or misuse information through no fault at ours.
            We will nevertheless deploy reasonable security arrangements to
            protect your personal data as required by the privacy laws; However
            there can inevitably be no guarantee of absolute security such as
            but not limited to when unauthorized disclosure arises from
            malicious and sophisticated hacking by malcontents through no fault
            of ours
            <br />
            <br />
            7.6 We may use or share the information you provide to us if we are
            under a duty to disclose or share your information in order to
            comply with any legal or regulatory obligation or in order to
            enforce any obligation against you or to protect our rights and our
            affiliates. This may include the exchange of information with other
            companies and organizations for the purposes of fraud protection and
            prevention
            <br />
            <br />
            8. PURPOSE OF PROCESSING
            <br />
            8.1 Your information will allow us to provide you with access to the
            particular parts of the mobile application or website relevant to
            you and allow us to supply the services you require.
            <br />
            <br />
            8.2 We will use your information for the purpose of fulfilling
            service orders placed by you, processing any other transaction
            authorized or made by you with us, informing you of special offers
            and providing other marketing information to you which we think you
            may find of interest, undertaking services or customer
            research/development.
            <br />
            <br />
            8.3 Your information may be used by us to contact you for your
            opinions on the mobile application, website, services and our
            business, and to notify you of changes or developments to the mobile
            application, website, services and our business.
            <br />
            <br />
            9. SECURITY MEASURES
            <br />
            9.1 We have implemented security policies, rules and technical
            measures to protect the personal data that we have under our control
            from unauthorized access, improper use and disclosure, unauthorized
            destruction or accidental loss, your personal data is contained
            behind secured networks and is only accessible by a limited number
            of persons who have special access rights to such systems, and are
            required to keep the personal data confidential. However, there can
            inevitably be no guarantee of absolute security such as but not
            limited to when unauthorized disclosure arises from malicious and
            sophisticated hacking by malcontents through no fault of ours.
            <br />
            <br />
            9.2 You are solely responsible for keeping your username, password
            and other account details confidential. If you have concerns about
            your password, account details, or are suspicious about any
            unauthorized use of your account, you should contact us immediately.
            We can deactivate or suspend your account at any time.
            <br />
            <br />
            9.2 WE DO NOT GUARANTEE THE SECURITY OF PERSONAL DATA AND/OR OTHER
            INFORMATION THAT YOU PROVIDED ON THIRD PARTY SITES.
            <br />
            <br />
            10. PAYMENT DATA
            <br />
            10.1 We employ a third-party provider for processing the payments,
            they have access to personal information needed to perform their
            functions, but may not use it for other purposes.
            <br />
            <br />
            11. TRANSFER OF INFORMATION
            <br />
            11.1 Your personal data and/or information may be transferred to,
            stored or processed outside of your country. Forent will only
            transfer your information overseas in accordance with privacy laws.
            <br />
            <br />
            12. WITHDRAWING CONSENT
            <br />
            12.1 You may withdraw your consent for the collection, use and/or
            disclosure of your personal data in our possession or under our
            control by sending an email to info@forent-app.com and we will
            process such request in accordance with this privacy policy and our
            obligations under the privacy laws and other applicable laws.
            However, your withdrawal of consent may mean that we will not able
            to continue providing our services to you and we may need to
            terminate your existing relationship and/or the contract you have
            with us
            <br />
            <br />
            13. UPDATES TO POLICY
            <br />
            We reserve the right to vary this from time to time. Our updated
            policy will be displayed on our mobile application and website. And
            by continuing to use and access the mobile application and website,
            following such changes, you agree to be bound by any variation made
            by us. It is your responsibility to check this policy from time to
            time to verify such variations.
            <br />
            <br />
            14. INQUIRY, CONCERNS OR COMPLAINTS
            <br />
            14.1 If you have any inquiries, concerns or complaints about our
            privacy practices, you may contact us by e-mail at
            info@forent-app.com
          </p>
        </div>
      </div>
    );
  }
}
export default PrivacyPolicy;
