// Import the functions you need from the SDKs you need
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyAWkjb5THg5-iMBdNbTm_kP6UHKoNY2RAA",
    authDomain: "forent-56a69.firebaseapp.com",
    projectId: "forent-56a69",
    storageBucket: "forent-56a69.appspot.com",
    messagingSenderId: "420068561164",
    appId: "1:420068561164:web:2bb48c28fc4bd6d8947929",
    measurementId: "G-SDN7VDKKTL"
};
