import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { analyticsLogEvent } from "../App";
import "./home-fix.css";

class Home extends Component {
  componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "https://cdn.tailwindcss.com";
    // script.async = true;
    // document.body.appendChild(script);
    analyticsLogEvent('webapp_home_page');
  }
  // onClickDownload(_platform){
  //   let location = ''
    
  //   analyticsLogEvent(`webapp_click_download_${_platform}`);
  //   window.location.replace(location);
  // }
  render() {
    return (
      <div id="container" className="max-w-screen">
        <Helmet>
          <body data-bg-home="true" />
        </Helmet>

        <div id="home-background" className="max-w-screen bg-gray-500 p-10">
          <div className="space-y-5 md:grid md:grid-cols-2 ">
            <div className="md:flex-col justify-center lg:flex items-center">
              <center style={{ paddingBottom: "30px" }}>
                <img
                  src="assets/img/p2p.png"
                  alt=""
                  className="w-96"
                  style={{ width: "28rem" }}
                />
              </center>
              <span
                className="block mx-32 text-white lg:text-lg md:text-base"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  fontWeight: "200",
                  fontSize: "1.125rem",
                }}
              >
                Click to download Forent App now!
              </span>
              <div
                className="justify-center flex space-x-3 home--cta"
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  borderRadius: "15px",
                  width: "280px",
                  padding: "10px",
                }}
              >
                <a
                  className="h-10"
                  href="/redirect/ios"
                >
                  <img
                    src="/assets/img/home/apple-store2.png"
                    alt=""
                    className="h-10"
                  />
                </a>
                <a
                  className="h-10"
                  href="/redirect/android"
                >
                  <img
                    src="/assets/img/home/google-play2.png"
                    alt=""
                    className="h-10"
                  />
                </a>
              </div>
            </div>
            <div className="hero--image">
              {/* <img src="/assets/img/home/forent-web-banner.png" alt="" className="h-96 space-x-5"/> */}
              <img
                src="/assets/img/home-app.png"
                alt=""
                className="h-96 space-x-5"
              />
            </div>
          </div>
        </div>

        <div className="bg-white px-4 px-lg-0">
          <br />
          <br />
          <div className="lg:flex items-center justify-center ">
            <div className="lg:flex lg:z-10 lg:absolute lg:space-x-20">
              <div
                className="bg-white p-12 rounded-lg shadow-2xl overflow-hidden mb-3 mb-lg-0"
                style={{ borderRadius: "40px" }}
              >
                <center>
                  <span
                    className="text-blue-600"
                    style={{
                      fontWeight: "700",
                      color: "#71C7EC",
                      fontSize: "24px",
                    }}
                  >
                    Trusted Verification{" "}
                  </span>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    We screen all users so you can{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    confidently use the app with{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    verified hosts and guests{" "}
                  </p>
                </center>
              </div>

              <div
                className="bg-white p-12 rounded-lg shadow-2xl overflow-hidden mb-3 mb-lg-0"
                style={{ borderRadius: "40px" }}
              >
                <center>
                  <span
                    className="text-yellow-500"
                    style={{
                      fontWeight: "700",
                      color: "#F2B548",
                      fontSize: "24px",
                    }}
                  >
                    Wide Range of Options{" "}
                  </span>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    Choose from hundreds of car listings{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    available based on your needs,{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    preference, and budget
                  </p>
                </center>
              </div>

              <div
                className="bg-white p-12 rounded-lg shadow-2xl overflow-hidden"
                style={{ borderRadius: "40px" }}
              >
                <center>
                  <span
                    className="text-yellow-500"
                    style={{
                      fontWeight: "700",
                      color: "#F4B0C0",
                      fontSize: "24px",
                    }}
                  >
                    Safe & Friendly Community
                  </span>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    Help us build a better renting
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    experience by rating and{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      color: "#3F3534",
                      fontSize: "14px",
                    }}
                  >
                    leaving a review to our hosts
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* <div className="p-6 lg:p-36 lg:px-40 md:grid md:grid-cols items-center">
                    <div className="md:flex md:flex-col md:justify-center">
                        <p className="md:text-lg text-gray-900 text-justify">
                        Tired of the long and tedious process every time you rent? Forent is here to provide a safe and innovative space for merchants and renter alike.
                        Forent is a Filipino-made app that caters to a wide range of rental services, from cars to fashion items, all while considering your location, preference, and budget. With this platform, convenient and safe renting transactions is now within reach!
                        </p>
                    </div>

                    <div className="bg-white space-y-5">
                        <center>
                            <div className="lg:space-y-0 w-80 h-96 rounded-lg shadow-2xl">   
                            <video src="../assets/video/forent-video.mp4" class="mx-auto" autoplay={false} controls={true} loop=""></video>
                            </div>
                        </center>
                    </div>
              
                </div>  */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-4 lg:gap-0 p-6 lg:p-36 lg:px-40">
          <div className="md:flex md:flex-col md:justify-center">
            <p
              className="md:text-lg text-gray-900 text-justify"
              style={{ fontWeight: "400", color: "#3F3534", fontSize: "14px" }}
            >
              Tired of the long and tedious process every time you rent? Forent
              is here to provide a safe and innovative space for merchants and
              renter alike.
              <br />
              <br />
              Forent is a Filipino-made app that caters to a wide range of
              rental services, from cars to fashion items, all while considering
              your location, preference, and budget. With this platform,
              convenient and safe renting transactions is now within reach!
            </p>
          </div>
          <div className="bg-white space-y-5">
            <center>
              <div className="lg:space-y-0 w-80 h-96 rounded-lg shadow-2xl video--wrapper">
                {/* <img src="assets/img/home/forent-girl.png" alt="girl" className="mx-auto"/> */}
                <video
                  src="/assets/video/forent-video.mp4"
                  className="mx-auto"
                  autoPlay={false}
                  controls={true}
                  loop=""
                  style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "20px",
                  }}
                ></video>
              </div>
            </center>
          </div>
        </div>

        {/* <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-4 lg:gap-0">
                        <div className="text-black text-base font-bold text-center p-20 rectangle3">Image/Illustration</div>
                        <div className=" text-white text-lg text-right p-20 rectangle2">Donec rutrum felis lectus
                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at justo eros. Maecenas commodo neque vestibulum, posuere tortor in, pharetra magna.</p>
                            <br/>
                            <a href="!#" className="py-2 px-4 hover:bg-amber-500 text-white hover:text-yellow-800 rounded-full transition duration-300 rectangle4">
                                How it works</a>
                        </div>
                        <div className="text-black text-base font-bold text-center p-20 rectangle3">Image/Illustration</div>
                        <div className="text-white text-lg text-right p-20 rectangle2">Vivamus dui massa
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at justo eros. Maecenas commodo neque vestibulum.</p>
                            <br/>
                            <a href="!#" className="py-2 px-4 hover:bg-amber-500 text-white hover:text-yellow-800 rounded-full transition duration-300 rectangle4">
                                How it works</a>
                        </div>
                    </div>
                </div> */}

        {/* <div className="bg-white">
                    <h2 className="text-center font-bold p-20 text-2xl">Testimonials</h2>
                    <div className="hidden md:flex md:absolute md:left-96"> <img src="assets/img/home/testi2.png" alt="" className="h-12"/> </div>
                    <div className="hidden md:flex px-6 absolute left-1/2"> <img src="assets/img/home/testi2.png" alt="" className="h-12"/> </div>
                    
                    <div className="lg:flex items-center justify-center lg:space-x-5 py-6" >
                        <div className="bg-white p-8 font-bold rounded-lg border border-red-300 border-red-300 overflow-hidden"> 
                            <p> “Vivamus sagittis, lectus quis </p>
                            <p> elementum <strong class="text-amber-500"> pretium, turpis tortor </strong> </p>
                            <p> commodo ex, euismod suscipit </p>
                            <p> arcu nec ligula.” </p>
                            <p className="text-sm font-light"> <br/>Lastname, First Name</p>
                        </div>
                  
                        <div className="font-bold bg-white grid grid-cols-2 gap-2 p-8 rounded-lg border border-red-300 overflow-hidden"> 
                            <div> 
                                <p> “Phasellus posuere, enim eget</p>
                                <p> dapibus accumsan sapien lorem</p>
                                <p> pulvinar nulla  <strong className="text-amber-500"> id tristique ipsum </strong></p>
                                <p>nulla aliquet dolor." </p>
                                <p className="text-sm font-light"> <br/>Lastname, First Name</p> </div>
                            <div className="place-content-end px-24 bg-white rounded-lg bg-gray-100 w-28 overflow-hidden"> </div>
                        </div>
                    </div>

                    <div className="rectangle2">
                        <div className="flex">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-4 lg:gap-1 p-20">
                                <div className="text-white font-bold">Superapp in the Philippines targets to be one-stop-shop for all your renting needs
                                    <p className="font-light">Curabitur sit amet magna sapien. Cras semper leo at dolor gravida interdum. Maecenas lacinia tempor nunc porttitor vestibulum. Integer ac varius mauris, nec vehicula enim. Vivamus lobortis ac massa ac venenatis. </p>
                                </div>
                                <div className="md:items-center lg:mx-auto bg-white w-56 h-44 rounded-lg shadow-2xl overflow-hidden"> </div>
                                <div className="text-white font-bold">Finally! An all in one platform for everything renting! Forent
                                    aims to be go-to safe app for Filipinos when their renting
                                    <p className="font-light">Curabitur sit amet magna sapien. Cras semper leo at dolor gravida interdum. Maecenas lacinia tempor nunc porttitor vestibulum. Integer ac varius mauris, nec vehicula enim. Vivamus lobortis ac massa ac venenatis. </p>
                                </div>
                                <div className="lg:mx-auto lg:py-24 bg-white w-56 h-44 rounded-lg shadow-2xl overflow-hidden"> </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white">
                        <div className="text-center font-bold p-20 text-2xl"> Forent Security 
                            <p className="p-5 font-light text-lg"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at justo eros.</p>
                            <p className="p-0 font-light text-lg"> Maecenas commodo neque vestibulum, posuere tortor in, pharetra magna.</p>
                        </div>

                        <div className="items-center justify-center lg:space-x-20 p-4 lg:flex">
                            <div className=" p-12 overflow-hidden"> 
                                <center><img src="assets/img/home/Ellipse.png" alt="" className="items-center justify-center object-none object-center"/></center>
                                <p className="text-black text-center font-bold"> Trusted User Verification </p>
                                <p className="font-light text-center">Maecenas commodo neque vestibulum, </p>
                                <p className="font-light text-center"> posuere tortor in, pharetra magna.</p>
                            </div>

                            <div className=" p-12  overflow-hidden"> 
                                <center>  <img src="assets/img/home/Ellipse2.png" alt="" class="object-center"/> </center>
                                <p className="text-black text-center font-bold"> Validated Legit Merchant </p>
                                <p className="font-light text-center">Maecenas commodo neque vestibulum, posuere</p>
                                <p className="font-light text-center">  tortor in, pharetra magna.</p>
                            </div>

                            <div className="p-12  overflow-hidden"> 
                              <center>  <img src="assets/img/home/Ellipse3.png" alt="" className="object-center"/> </center>
                                <p className="text-black text-center font-bold"> Secure Payment Gateway </p>
                                <p className="font-light text-center">Maecenas commodo neque vestibulum,</p>
                                <p className="font-light text-center">tortor in, pharetra magna.</p>
                            </div>

                            <div className=" p-12  overflow-hidden"> 
                                <center> <img src="assets/img/home/Ellipse4.png" alt="" className="object-center"/> </center>
                                <p className="text-black text-center font-bold"> User Ratings and Reviews </p>
                                <p className="font-light text-center">Maecenas commodo neque vestibulum, </p>
                                <p className="font-light text-center"> posuere tortor in, pharetra magna.</p> 
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}
export default Home;
