// eslint-disable-next-line
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./blogs-fix.css";
import { analyticsLogEvent } from "../App";
String.prototype.trunc = 
function(n){
  return this.substr(0,n-1)+(this.length>n?'...':'');
};
function Blogs(props) {
  const [articles] = useState(props.articles || []);
  useEffect(() => {
    analyticsLogEvent('webapp_blogs_page');
  });
  const navigate = useNavigate();
  const OnHandleClick = (event) => {
    event.preventDefault();
    console.log(props);
    props.setActiveArticle(
      articles[event.target.attributes.articleindex.value]
    );
    navigate(
      `/articles?title=${
        articles[event.target.attributes.articleindex.value].header
      }`
    );
    analyticsLogEvent(`webapp_navigate_article__${articles[event.target.attributes.articleindex.value].header.toLowerCase()}_page`);
  };
  return (
    <>
      <section style={{ background: "#F4ECDC" }} id="introductionSection">
        <div className="container">
          <div className="row">
            <div
              className="col"
              style={{
                marginRight: "50px",
                marginLeft: "50px",
                marginTop: "142px",
              }}
            >
              <div>
                <img
                  className="img-fluid"
                  alt=""
                  style={{
                    borderRadius: "30px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src="/assets/img/blog/articles/Art_Header.jpeg"
                  width="auto"
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-sm-row flex-column blogs-fix-1"
            // style={{ marginRight: "0px", marginLeft: "45px" }}
          >
            <div
              className="p-2 flex blogs-fix-3"
              // style={{ marginLeft: "25px", marginRight: "45px" }}
            >
              <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                <div
                  className="card border-0"
                  style={{ background: "rgba(255,255,255,0)" }}
                >
                  <div
                    className="card-body blogs-fix-2"
                    style={{ background: "#f9f6ef00" }}
                  >
                    <p
                      style={{
                        fontFamily: "Quicksand, sans-serif",
                        display: "inline-flex",
                        color: "#F15D3E",
                        fontWeight: "bold",
                      }}
                    >
                      Category&nbsp;
                      <svg
                        style={{ margin: "5px 0px 0px 0px" }}
                        className="bi bi-dot text-black-50"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      </svg>
                      <span className="text-black-50">April 2 , 2022</span>
                    </p>
                    <h1
                      className="fs-2 fw-bold text-start d-flex"
                      style={{ fontFamily: "Quicksand, sans-serif" }}
                    >
                      Rent Easily with Forent, PH’s Newest Rental App
                    </h1>
                    <a className="card-link stretched-link" href="#!"/>
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Quicksand, sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      People can rent almost anything nowadays— houses,
                      vehicles, equipment, clothing, you name it! But the
                      renting process can be tiring because of the numerous
                      platforms you have to sift through just to meet your
                      preferences with the hopes of not being scammed. Ever
                      wonder how to choose a reliable renting partner at your
                      convenience? Start by choosing the right platform for your
                      rental needs.&nbsp;
                      <br />
                      <br />
                    </p>

                    <h6
                      className="fs-4 fw-bold text-start d-flex"
                      style={{ fontFamily: "Quicksand, sans-serif" }}
                    >
                      The Forent Renting App
                    </h6>
                    <a className="card-link stretched-link" href="#!" />
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Quicksand, sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      Launched in 2021, Forent is a digital platform that
                      connects merchants and renters, providing convenient
                      transactions and secure rental experiences. This app
                      serves as a one-stop shop for renters, and a space for
                      merchants to scale their products and services.&nbsp;
                      <br />
                      <br />
                    </p>

                    <h6
                      className="fs-4 fw-bold text-start d-flex"
                      style={{ fontFamily: "Quicksand, sans-serif" }}
                    >
                      The convenience of renting in a few taps
                    </h6>
                    <a className="card-link stretched-link" href="#!" />
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Quicksand, sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      Driven by the desire to provide a go-to platform for
                      renting, Forent changes the game in the industry with its
                      safety features, FREE subscriptions, and ZERO listing
                      fees.&nbsp;
                      <br />
                      <br />
                      “Not only does Forent want to eliminate scammers through
                      its merchant screening, ratings, and review features, but
                      we also want to fix the existing long and tedious process
                      of the overall rental experience,” said Jayvee de Leon,
                      Chief Executive Officer of Forent.
                      <br />
                      <br />
                    </p>

                    <h6
                      className="fs-4 fw-bold text-start d-flex"
                      style={{ fontFamily: "Quicksand, sans-serif" }}
                    >
                      The future of rental industry
                    </h6>
                    <a className="card-link stretched-link" href="#!" />
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Quicksand, sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      Continuously ramping up its effort to protect their
                      clients and offer opportunities to their partner, Forent
                      is in talks with Gigacover, an insurtech company based in
                      Singapore, to give our merchants and renters appropriate
                      protection against losses and damages. In addition to
                      that, the app is also partnering with BillEase, making the
                      ‘rent now, pay later’ option available.&nbsp;
                      <br />
                      <br />
                      As the first Filipino-made application that caters to
                      wide-ranging rental services in the country, Forent is a
                      promising startup having secured an external financing in
                      less than a month after its official launch.
                      <br />
                      <br />
                      Whether you're someone looking to diversify your income
                      through renting out your items, or someone who needs help
                      finding the best deal for what you are looking for, Forent
                      is the app for you!
                      <br />
                      <br />
                      Forent is available for download on Android and iOS
                      devices. Visit Forent’s website and Facebook page for more
                      information.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: "#FAF8F4" }}>
        <div className="container">
          <div
            className="recent--post"
            // style={{ marginRight: "50px", marginLeft: "50px" }}
          >
            <h1
              className="fs-3"
              id="recentpostSection"
              style={{
                paddingBottom: "50px",
                paddingTop: "90px",
                marginLeft: "0px",
                marginBottom: "20px",
              }}
            >
              Recent Posts
            </h1>
            <div className="row gx-5">
              <div className="col col-lg-9">
                <div className="row">
                  {articles &&
                    articles.map((a, ai) => {
                      var p = document.createElement("p");
                      p.innerHTML = a.description;
                      return (
                        <div
                          className="col col-lg-6"
                          style={{ paddingBottom: 0 }}
                          key={ai}
                        >
                          <div className="card border-0">
                            <div
                              className="card-body"
                              style={{ padding: 0, background: "#FAF8F4" }}
                            >
                              <div style={{ width: "auto", height: "270px" }}>
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "fill",
                                  }}
                                  src={a.image}
                                  alt=""
                                />
                              </div>
                              <p
                                className="card--header"
                                style={{
                                  fontFamily: "Quicksand, sans-serif",
                                  display: "inline-flex",
                                  color: "#F15D3E",
                                  fontWeight: "bold",
                                  margin: "25px 0px 10px 0px",
                                  width: "100%",
                                }}
                              >
                                <span>{a.header}&nbsp;</span>
                                <svg
                                  className="bi bi-dot text-black-50"
                                  style={{ margin: "5px 0px 0px 0px" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                                <span className="text-black-50">
                                  {a.created_at}
                                </span>
                              </p>
                              <h5
                                className="fw-bold stretched-link article-title"
                                style={{
                                  width: "auto",
                                  fontFamily: "Quicksand, sans-serif",
                                }}
                              >
                                <strong>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    {a.title}
                                  </span>
                                </strong>
                              </h5>
                              <a
                                className="card-link stretched-link"
                                href="!#"
                                articleindex={ai}
                                onClick={OnHandleClick}
                              />
                              <div
                                className="article-description"
                                // dangerouslySetInnerHTML={{
                                //   __html: `<p class="text-break">${div.innerText}</p>`,
                                // }}
                              >
                                <p className="card-text">{p.innerText.trunc(255)}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col">
                <div
                  className="position-sticky top-0"
                  style={{ paddingLeft: "25PX", paddingTop: "25px" }}
                >
                  <h4 style={{ fontFamily: "Quicksand, sans-serif" }}>
                    <a href="#recentpostSection">Recent Posts</a>
                    <br />
                  </h4>
                  <h5
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      marginTop: "30px",
                      borderBottom: "1px solid #D2D2D2",
                    }}
                  ></h5>
                  {/* <h4 style={{fontFamily: 'Quicksand, sans-serif'}}>Featured In<br /></h4>
                    <h5 style={{width: "100%", marginBottom: "20px", marginTop: "30px", borderBottom: "1px solid #D2D2D2"}}></h5> */}
                  <h4 style={{ fontFamily: "Quicksand, sans-serif" }}>
                    <a href="#introductionSection">Category</a>
                    <br />
                  </h4>
                  <h5
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      marginTop: "30px",
                      borderBottom: "1px solid #D2D2D2",
                    }}
                  ></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Blogs;
