import React, { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ClipboardJS from "clipboard";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { analyticsLogEvent } from "../App";

function Articles(props) {
  // Directs to the top of the page
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
  //   const [article] = useState(props.article || null);
  //   console.log(window.location);
  //   const navigate = useNavigate();
  //   if (article !== null) {
  //     navigate("/blogs");
  //   }
  const [show, setShow] = useState(false);
  const targetText = useRef(null);
  const articles = props.articles;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const title = params.get("title");
  // console.log("title", props);
  const find = articles.find((i) => i.header === title);
  const shrLink = `https://www.forent-app.com/shr/${encodeURIComponent(title)}`;
  useEffect(() => {
    analyticsLogEvent(`webapp_article_${title.toLowerCase()}_page`)
  })
  const onCopy = (e) => {
    e.preventDefault();
    e.clipboardData.setData("text/plain", shrLink);
    if (!show) {
      setShow(true);
      analyticsLogEvent(`webapp_article_copy_shrlink_${title.toLowerCase()}`)
    }
    new Promise((resolve) => setTimeout(() => setShow(false), 1000));
  };
  const onClickCopy = (e) => {
    new ClipboardJS("#shrlink", {
      text: function (trigger) {
        if (!show) {
          setShow(true);
          analyticsLogEvent(`webapp_article_copy_shrlink_${title.toLowerCase()}`)
        }
        new Promise((resolve) => setTimeout(() => setShow(false), 1000));
        return shrLink;
      },
    });
  };
  return (
    find && (
      <div onCopy={onCopy}>
        <section className="py-4 py-xl-5">
          <div className="container">
            <div className="row mb-5">
              <div
                className="col-md-8 col-xl-6 text-center mx-auto"
                style={{ width: "806px" }}
              >
                {/* Article Banner */}
                <div
                  className={
                    find.bannerPosition
                      ? "container text-left p-0 flex align-items-start"
                      : "container text-left p-0 flex align-items-center"
                  }
                  style={{
                    maxHeight: "200px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      paddingTop: `${find.imagePadding ? "120px" : "0"}`,
                    }}
                    src={find.image}
                    alt="Article Banner"
                  />
                </div>
                {/* Article Banner */}
                <div
                  className="container"
                  style={{
                    textAlign: "left",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    marginTop: "40px", // before banner 117px
                    marginBottom: "40px",
                    fontFamily: "Quicksand, sans-serif",
                  }}
                >
                  <span>
                    Blog&nbsp; /{" "}
                    <span>
                      &nbsp;{" "}
                      <span style={{ color: "#F15D3E" }}>{find.header}</span>
                    </span>
                  </span>
                </div>
                <h1
                  className="w-lg-50"
                  style={{
                    textAlign: "left",
                    fontFamily: "Quicksand, sans-serif",
                  }}
                >
                  <strong id="shrlink" onClick={onClickCopy}>
                    <span
                      style={{ color: "rgb(0, 0, 0)", cursor: "pointer" }}
                      ref={targetText}
                    >
                      {find.title}
                    </span>
                    <Overlay
                      target={targetText.current}
                      show={show}
                      placement="top"
                    >
                      {(props) => <Tooltip {...props}>Copied</Tooltip>}
                    </Overlay>
                  </strong>
                  <br />
                </h1>
                <p
                  style={{
                    textAlign: "left",
                    marginBottom: "39px",
                    marginTop: "15px",
                  }}
                >
                  {/* April 2,&nbsp; 2022 */}
                  {find.created_at}
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    fontFamily: "Quicksand, sans-serif",
                    marginBottom: "20px",
                  }}
                  dangerouslySetInnerHTML={{ __html: find.description }}
                ></p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
}

export default Articles;
